<!--
 * @Author: your name
 * @Date: 2022-03-10 15:29:54
 * @LastEditTime: 2022-04-19 16:57:25
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \wifivue\src\views\index\components\complete\index.vue
-->
<template>
  <div class="cinplete">
    <van-overlay :show="show">
      <div class="cnewwrapper" @click.stop>
        <img src="@/assets/images/index/100.png" alt="" class="newimg">
        <div class="newtitle">刷新成功</div>
        <div class="newtext">如果遇到异常，设备刷新成功后还需要<span class="toast">重启</span>手机，({{num}}s后自动关闭)</div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
export default {
  data() {
    return{
      num:20,
    }
  },
  props:{
    show:{
      typeof:Boolean,
      default:false
    }
  },
  watch:{
    show:{
      handler(newvalue,oldvalue){
        if(newvalue){
         const setitme = setInterval(() => {
        if(this.num > 0){
          this.num--
        }else{
          this.num = 20
          clearInterval(setitme)
          this.$emit("clcomplete")
        }
      }, 1000);
      }
      },
      deep:true,
      immediate:true
      
    }
  },
}
</script>
<style lang="less" scoped>
.cinplete{
  /deep/.van-overlay{
    z-index: 6 !important;
  }
  .cnewwrapper{
    width: 70%;
    height: 220px;
    background-color: #ffffff;
    border-radius: 10px;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
    flex-direction: column;
    .newimg{
      width: 80px;
      height: 80px;
      display: block;
    }
    .newtitle{
       margin-top: 20px;
      font-size: 20px;
      font-weight: 500;
    }
    .newtext{
      color: rgb(68, 68, 68);
      font-size: 12px;
      margin-top: 20px;
      padding: 0 20px;
      box-sizing: border-box;
      line-height: 1.25;
      .toast{
        color: red;
      }
    }
}
}
</style>