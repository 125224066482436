<!--
 * @Author: your name
 * @Date: 2022-03-10 14:47:21
 * @LastEditTime: 2022-04-19 15:12:13
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \wifivue\src\views\index\components\restartloading\index.vue
-->
<template>
  <div class="loadingtest">
    <van-overlay :show="show">
      <div class="wrapper" @click.stop>
        <van-loading size="60px" type="spinner" color="#19cc47"></van-loading>
        <h2 class="h2">刷新中...</h2>
        <div class="text">设备刷新中,请稍等({{num}}秒)</div>
    </div>
</van-overlay>
  </div>
</template>
<script>
export default {
  data() {
    return{
      num:10
    }
  },
  props:{
    show:{
      typeof:Boolean,
      default:false
    }
  },
  watch:{
    show:{
      handler(newvalue,oldvalue){
        if(newvalue){
         const setitme = setInterval(() => {
        if(this.num > 0){
          this.num--
        }else{
          this.num = 10
          clearInterval(setitme)
          this.$emit("closeint")
        }
      }, 1000);
      }
      },
      deep:true,
      immediate:true
      
    }
  },
  methods:{

  },
}
</script>
<style lang="less" scoped>
.loadingtest{
  /deep/.van-overlay{
  z-index: 6 !important;
}
  .wrapper{
    width: 70%;
    height: 220px;
    background-color: #ffffff;
    border-radius: 10px;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
    flex-direction: column;
    .h2{
      margin-top: 20px;
      font-size: 20px;
    }
    .text{
      color: rgb(68, 68, 68);
      font-size: 12px;
      margin-top: 20px;
    }
  }
}
</style>