/*
 * @Author: your name
 * @Date: 2022-04-08 15:18:11
 * @LastEditTime: 2022-06-14 16:04:00
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifisvn\src\mixi\mixi.js
 */
import isweixin from "@/untils/isweixin.js"
let mixin = {
  data(){
    return {
      // openid:"",  // 微信的openid
      cardinfo:{},  //设备的详细信息
      offcardinfo:{},  // 官方实名状态信息
      infolist:[
        {
          text:"设备余额",
          money:"",
          company:"元",
          texttop:"#ffffff"
        },
        {
          text:"已用",
          money:"",
          company:"",
          color:"#ffffff",
          isloading:true,
          texttop:"#ffffff"
        },
        {
          text:"剩余天数",
          money:"",
          company:"天",
          texttop:"#ffffff"
        },
        {
          text:"设备状态",
          money:"",
          company:"",
          texttop:"#ffffff"
        }
      ],  // 详细信息区域列表
    }
  },
  mounted(){
    // this.code = this.$route.query.code
    if(this.$route.query.openid){
      window.sessionStorage.setItem("openid",this.$route.query.openid)
    }
  },
  // watch:{
  //   code:{
  //     handler(newvalue,oldvalue){
  //       if(newvalue  != "" && newvalue != undefined){
  //         window.sessionStorage.setItem("wxcode",newvalue)
  //         let _data2 ={
  //           iccid:window.sessionStorage.getItem("iccid"),
  //           phone:window.sessionStorage.getItem("phone"),
  //           code:this.$route.query.code
  //         }
  //         if((window.sessionStorage.getItem("openid") == "null" || !window.sessionStorage.getItem("openid"))&&
  //         (newvalue != "" || newvalue != undefined || !newvalue)
  //         ){
  //           this.getOpneid(_data2)
  //         }
  //       }
  //     },
  //     deep:true
  //   }
  // },
  methods:{
     // 根据iccid获取当前设备信息
    async getcardstate(){
      let data ={
        iccid:sessionStorage.getItem("iccid")?sessionStorage.getItem("iccid"):this.iccid
      }
     await this.getState({data:data}).then(res =>{
        if(res.data.code == 1){
          this.cardinfo = res.data.info
          if(this.cardinfo.iccid_status != "正常"){
            this.infolist[3].texttop = "#fff600"
          }
          if(res.data.info.phone != ""){
            sessionStorage.setItem("phone",res.data.info.phone)
          }
          if(res.data.info.phone != "" && res.data.info.buy_id > 0 && JSON.stringify(res.data.info) != "{}" && res.data.info.ispick == 2 && res.data.info.sl_real_state == 1){
            let data = {
              phone:sessionStorage.getItem("phone"),
              iccid:sessionStorage.getItem("iccid"),
              path:"/selectpack"//guide引导页跳转到选择套餐页面
            }
            // this.$router.push("/index")
            this.isopenid(data)
          }
          if(res.data.info.sl_real_state == 0){
            this.show = true
            this.showToast = false
          }
        }else if(res.data.code == 0){
          this.$toast(`${res.data.msg}`)
          sessionStorage.clear("iccid")
          setTimeout(() =>{
            this.$router.push("/service")
          },2000)
        }
        else if(res.data.code == 2){
          this.cardinfo = res.data.info
        }
      })
    },
    // 根据iccid获取当前官方实名信息
  async  getoffinfo(num){
      let data ={
        iccid:sessionStorage.getItem("iccid")?sessionStorage.getItem("iccid"):this.iccid
      }
      if(num == 1){
        this.percentage= ""
      }
     await this.officialState({data}).then(res =>{
        if(res.data.code == 1){
          // console.log(res,'officialState');
          this.claerset = true
          this.offcardinfo = res.data.info
          // 因为当时考虑不全,需要判断是首页还是引导页的调用
          let buy_id = this.info?this.info.buy_id:this.cardinfo.buy_id
          let ispick = this.info?this.info.ispick:this.cardinfo.ispick
          if(buy_id > 0){
            if(ispick != 2){
            this.isShowToast = true
            }else{
             this.isShowToast = false
            }
          }else{
            this.isShowToast = false
          }
          this.infolist[1].money = Math.ceil(res.data.info.used)
          this.infolist[1].usedMoney = res.data.info.surplus_flow
          this.infolist[1].company = res.data.info.used_unit
          this.infolist[1].isloading = false
          if(this.offcardinfo.iccid_status != "正常"){
            this.infolist[3].texttop = "#fff600"
          }
          if(num == 1){
            this.isrotate = true
            if(this.offcardinfo.surplus_flow == 0 ){
              this.value = 0
              this.percentage = "0.00" + "%"
            }else{
              this.value=((this.offcardinfo.surplus_flow/this.info.total_flow)*100).toFixed(2)
              if(this.value>=100){this.value = 100}
              this.percentage = this.value + "%"
            }
            this.surplus_flow = Math.floor(this.offcardinfo.surplus_flow/1024)
            sessionStorage.setItem("surplus",Math.floor(this.offcardinfo.surplus_flow/1024))
            this.canverClire(this.value)
          }
        }else{
          this.$toast(`${res.data.msg}`)
        }
      })
    },
    // 进行Opendid的获取
    async getOpneid(data){
      await this.getCurOpenid({data:this.qs.stringify(data)}).then(res =>{
        if(res.data.code == 1){
          window.sessionStorage.setItem("openid",res.data.info.openid)
          this.openid = res.data.info.openid
          this.$router.push(sessionStorage.getItem("path"))
        }
      })
    },
    // 判断是否需要获取openid
    isopenid(data){
      // this.$router.push(data.path)
      if(window.sessionStorage.getItem("openid") != null && window.sessionStorage.getItem("openid") != 'null' && window.sessionStorage.getItem("openid") && window.sessionStorage.getItem("openid") != "undefined" ){
        this.$router.push(data.path)
      }else{
        if(isweixin()){
          let url =encodeURIComponent("https://"+window.location.host+data.path+"?iccid="+data.iccid)
          window.location.replace("https://swifi.yunruiot.com/getOpenid?url="+url +"&iccid="+data.iccid+"&phone="+data.phone)
        }else{
          this.$router.push(data.path)
        }
      }
    },
    // 需要登录的路径
    loginpath($event){
      this.isopenid($event)
    },
  }
}
export default mixin