<!--
 * @Author: your name
 * @Date: 2022-03-03 16:41:10
 * @LastEditTime: 2022-03-03 17:40:25
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \wifivue\src\views\index\components\umask\index.vue
-->
<template>
  <div class="umask">
    <van-overlay :show="show" @click="closeshow">
      <slot @click.stop></slot>
    </van-overlay>
  </div>
</template>
<style lang="less" scoped>

</style>
<script>
export default {
  data() {
    return{
    }
  },
  props:{
    show:{
      typeof:Boolean
    }
  },
  methods:{
    // 向父组件通知关闭遮罩层
    closeshow(){
      this.$emit("closeshow",false)
    },
  }
}
</script>