<!--
 * @Author: your name
 * @Date: 2022-04-01 10:56:15
 * @LastEditTime: 2022-06-22 13:47:08
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifi\src\views\index\index.vue
-->
<!--
 * @Author: your name
 * @Date: 2022-04-01 10:49:26
 * @LastEditTime: 2022-04-01 10:50:38
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifi\src\views\guide\index.vue
-->
<template>
  <div class="index">
    <div class="indexr-center" :class="{issmall:windowHeight}">
      <div class="emptybox"></div>
      <div class="center-top">
        <div class="left">
          <img src="@/assets/images/index/wifilist.png" alt="" class="wifi-img" @click="clkShowChange">
          <img src="@/assets/images/index/division.png" alt="" class="division">
          <div class="texttitle">{{showWName}}</div>
          <div class="left-status" :class="{noBuy:info.iccid_status != '正常' && info.iccid_status != '套餐到期',timeout:info.iccid_status == '套餐到期'}">
           {{info.iccid_status}}
          <img src="@/assets/images/index/horn.png" alt="" class="hron" v-if="info.buy_id > 0 && info.ispick !=2">
          <img src="@/assets/images/index/status.png" alt="" class="img" v-if="info.iccid_status == '正常'">
          <!-- <img src="@/assets/images/index/abnormal.png" alt="" class="img" v-if="info.iccid_status != '正常' && info.buy_id !=0 "> -->
          <img src="@/assets/images/index/person.png" alt="" class="img" v-if="info.buy_id > 0 && info.ispick !=2 && info.iccid_status != '套餐到期'">
          <img src="@/assets/images/index/timeout.png" alt="" class="img" v-if="info.iccid_status == '套餐到期'">
          </div>
        </div>
        <!-- <div class="right" @click="clkrestart"></div> -->
        <div class="right" @click="editShow = !editShow"></div>
      </div>
      <div class="noNameTr" v-if="isShowToast || info.iccid_status == '套餐到期' " @click="goSwitch" :class="{timeoutImg:info.iccid_status == '套餐到期'}">
        <div class="toptitle">{{info.iccid_status == '套餐到期'?'套餐已到期':info.ispick == 3?'实名待完善':'设备未实名'}}</div>
        <div class="bottomtitle">{{info.iccid_status == '套餐到期'?'请先选择套餐购买继续使用':'根据相关规定,请先实名认证'}}</div>
      </div>
      <div class="downLoad" v-if="info.iccid_status == '正常'&& downShow == true && info.ispick ==2 ">
        <div class="downLoad_left">
          <van-icon name="cross" color="#A6C4F4" size="16" @click="closeDown" style="margin-left:6px" />
          <img src="../../assets/images/index/logo.png" alt="">
          <div class="downLoad_title">更稳定使用格行随身Wi-Fi，推荐App</div>
          <!-- <div class="downLoad_tip">实时信号监测  随时了解设备的信号质量</div> -->
        </div>
        <div class="downLoad_right">
          <button @click="toDownLoad">去下载</button>
          
        </div>
      </div>

      <div class="echarts">
         <div id="myChart" :style="{ width: '260px', height: '270px' }"></div>
         <div class="echbox-text">
           <div class="text-top">{{surplus_flow}}<span class="text-sapn">GB</span></div>
           <div class="surplus" v-if="percentage != ''">
             <span class="surplus-sapn"></span> {{percentage}}
           </div>
           <div class="newsurplus" v-else></div>
           <div class="text-center" @click="clkRes" v-if="isrotate">
             <img src="@/assets/images/index/refresh.png" alt="" class="text-center-img">刷新数据
           </div>
           <div class="text-change" v-else>
             <img src="@/assets/images/index/refresh.png" alt="" class="text-center-img">{{textRotate}}
           </div>
           <div class="text-right">
             <!-- <div class="textrp">已使用流量</div>
             <div class="texttb" v-if="this.value != ''">{{percentage}}</div> -->
           </div>
        </div>
      </div>
      <div class="funbox">
         <funboxitem v-for="(item,index) in funbox" :key="index" :hownum="funbox.length" :item="item" @loginpath="loginpath($event)" @isswitch="newswifiShow = true" :bottomtext="'#99c0ff'"></funboxitem>
      </div>
      <div class="kefu" @click="clkshow">
        <img src="../../assets/images/index/kefu.gif" alt="">
        <img src="../../assets/images/index/kefu1.png" alt="" style="display: block; margin-top: -10px;">
      </div>
      <div class="info">
        <div class="info_ICCID" v-if="info.real_type == 4 || info.real_type == 5">
          设备号：{{info.iccid}}
        </div>
        <!-- 4是双网  5是三网 -->
        <div class="change-gif" v-if="info.real_type == 4 || info.real_type == 5" @click="changeNetwork">
          <img v-if="info.real_type == 4" src="../../assets/images/index/change.gif" alt="">
          <img v-if="info.real_type == 5" src="../../assets/images/index/change_3.gif" alt="">
        </div>
        <div class="info-top"></div>
        <div class="infobox">
          <funboxitem v-for="(item,index) in infolist" :key="index" :hownum="infolist.length" :item="item" :bottomtext="'#99c0ff'">
            <div class="text-top">
              <span class="sapn" :class="{isstatus:index == 3?true:false}" :style="{color:item.texttop}">{{item.money}}</span> 
              {{item.company}}</div>
          </funboxitem>
        </div>
        <!--  @click="newloginpath()" -->
        <div class="info-bottom" v-if="isExperience" >
          <div class="bottom-left">{{info.buy_name}}</div>
          <div class="bottom-right" @click="newloginpath()">{{buttontext}}</div>
          <!-- <div class="bottom-left">
            <img src="../../assets/images/index/package_icon.png" alt="">
            购买套餐
          </div>
          <div class="bottom-right">
            <van-icon name="arrow" color="#95BDFE" size="14"/>
          </div> -->
        </div>
      </div>
    </div>
    <div class="toastBuyName" v-if="info.buy_id == 0">
      <div>该设备尚未购买套餐,请先购买套餐</div>
      <div class="Nright" @click="newloginpath()">去购买</div>
    </div>
    <umask :show=show @closeshow="closeshow($event)" @click.stop>
      <div class="restartcard" @click.stop>
        <img src="@/assets/images/index/100.png" alt="" class="rcimg">
        <div class="rectitle">刷新设备</div>
        <div class="rectoast">即将刷新设备，请确认是否刷新！</div>
        <div class="queryclick" @click="queryrestart">确认刷新</div>
        <div class="closeclick" @click="show =false">暂不刷新</div>
      </div>
    </umask>
    <restartloading :show="restartloading" @closeint="closeint"></restartloading>
    <complete :show="complete" @clcomplete="clcomplete"></complete>
    <van-popup v-model="editShow" position="bottom" :style="{ height: '180px',background:'#f5f7fa' }"  round>
      <div class="edit">
        <van-cell-group>
          <van-field v-model="wifiName" placeholder="请输入wifi名" maxlength="9" /><img src="@/assets/images/index/editb.png" alt="" class="van-img">
        </van-cell-group>
        <div class="editButton" @click="setAName">完成设置</div>
      </div>
    </van-popup>
    <newswifi :show="newswifiShow" @clkOverlay="newswifiShow = !newswifiShow" @change="change" :cardinfo="info" :setName="setName" @overGetlist="overGetlist"></newswifi>
    <bindphone :phoneShow="phoneShow" :iccid="iccid" @overlay="phoneShow = false" @clkIcon="phoneShow = false"></bindphone>
    <qrcode :showqrcode="entCodeShow" @clkqrcode = "clkshow($event)" :class="{isentCode:entCode}">
      <img :src="entCode" alt="" class="qrcode-img">
    </qrcode>
  </div>
</template>
<script>
import bindphone  from "@/components/bindphone/index.vue"
import funboxitem from "@/views/index/components/funboxitem.vue"
import umask from "@/components/umask/index.vue"
import mixin from "@/mixin/mixin.js"
import restartloading from "@/views/index/components/restartloading/"
import complete from "@/views/index/components/complete/"
import newswifi from "@/components/newswifi/index.vue"
import qrcode from "@/components/qrcode/index.vue"
import isPhone from "@/untils/isPhone.js"
import isweixin from "@/untils/isweixin.js"
export default {
  data() {
    return{
      entCodeShow:false,  // 企业微信是否`展示
      entCode:require("@/assets/images/guide/qrcode.jpg"),  // 企业微信路径
      funbox:[
        {
          url:require("@/assets/images/index/balance.png"),
          text:"余额充值",
          path:"/recharge",
          isswitch:false
        },
        {
          url:require("@/assets/images/index/setmeal.png"),
          text:"套餐办理",
          path:"/selectpack",
          isswitch:false
        },
        {
          url:require("@/assets/images/index/switch.png"),
          text:"切换设备",
          path:"/switchwifi",
          isswitch:true
        },
             
            
      ], // 功能区域列表
      isShowNetwork:false,
      setwindowHeight:document.documentElement.clientHeight, // 屏幕的实时高度
      windowHeight:false, // 屏幕实时设置
      iccid:"", // iccid号码(短号)
      info:{}, // 主页的所有信息
      show:false, // 重启遮罩层
      restartloading:false, // 重启的loading
      complete:false,  // 重启完成的loading
      currentRate: 0,
      value:0,  // 使用百分比
      claerset:false,  // 清除定时器
      myChartDom:"", // 已经被赋值的dom图表
      setit:"",  // 定时器的名字
      percentage:"",  // 百分比
      isShowToast:false,  // 是否隐藏当前实名认证
      option:{
        series: [
        {
          type: 'gauge',
          progress: {
              show: true,
              width: 2
          },
          axisLine: {
            lineStyle: {
             width: 2
          }
         },
        itemStyle:{
          color:"#15d85a",
          borderCap:"butt"
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show:false,
        },
        axisLabel: {
          show:false
        },
        // 关闭指针
        pointer:{
          show:false
        },
        title: {
          show: false,
        },
         // 显示内容
        detail: {
          valueAnimation: true,
          fontSize: 12,
          offsetCenter: [0, '-30%'],
          fontSize: 12,
          fontFamily:"bahn",
          fontWeight: 'bolder',
          formatter: "设备剩余流量",
          color: '#d4e4ff',
          show:true
          },
          data: [{
               value:"0"
          }]
        }
      ]
      },  //echarts图表数据
      editShow:false,  // 给wifi起别名的弹窗
      wifiName:"",   // 修改的wifi别名
      showWName:"",  // 要显示出来的WIFI别名
      newswifiShow:false,  // 新的wifi弹窗是否显示(用于切换wifi设备)
      setName:false,   // 设置过wifi别名之后
      isrotate:false,  // 是否展示正在刷新
      textRotate:"查询中...",  //是否展示正在查询流量
      surplus_flow:"--",  // 剩余的总量（GB）
      noclickBtn:false,  // 当切换wifi设备的时候禁止点击未购买套餐和未实名框，true是禁止
      phoneShow:false,  // 如果手机号被硕郎方清除，则拉起手机号绑定弹窗
      downShow:false,//下载提示
      hostUrl:window.location.host,   // 获取本地域名地址
    }
  },
  mixins:[mixin],
  mounted(){
    this.getUrl()
    this.myChartDom = this.$echarts.init(document.getElementById("myChart"))
    this.setIccid()
    this.getinfo()
    this.getoffinfo(1).then(res=>{
      this.infolist[1].money = Math.round(Math.ceil(this.info.total_flow -  this.infolist[1].usedMoney)/1024)>=0?Math.round(Math.ceil(this.info.total_flow -  this.infolist[1].usedMoney)/1024):0
      this.infolist[1].company ="GB"

    })
    this.addValue()
    this.downShow = true
    // if(isPhone()){
    //   this.downShow = true
    // }else{
    //   this.downShow = false
    // }
  },
  beforeRouteLeave (to, from, next) {
    // 导航离开该组件的对应路由时调用
    clearInterval(this.setit)
    // sessionStorage.setItem("surplus","--")
    next()    //允许跳转页面
  },
  computed:{
    // 如果是1000-2天则不显示
    isExperience(){
      if(this.info.exp_days <= 0){
        return false
      }else{
         return true
      }
    },
    // 按钮显示的文字
    buttontext(){
      if(this.info.pay_status == 1){
        return "去续期"
      }else{
        return "去订购"
      }
    },
     text() {
      return this.currentRate.toFixed(0) + '%';
    },
  },
  watch:{
    setwindowHeight:{
      // 获取当前屏幕的高度,用于兼容小屏幕手机的手机
      handler(newv,oldv){
        if(newv <= 667){
        this.windowHeight = true
       }else{
         this.windowHeight = false
        }
      },
      immediate:true,
      deep:true
    },
    // 如果计算的值是一个无穷大
    percentage:{
      handler(newv,oldv){
        let bol = (newv+"").indexOf("fin")
        if(bol != -1){
          this.percentage = ""
          // this.clkRes()
          
        }
      }
    }
  },
  methods:{
    // 展示企业微信弹窗
    clkshow($event){
      this.entCodeShow = $event.isTrusted
    },
    getUrl(){
      this.getWechatQRcode().then(res=>{
        if(res.data.code == 1){
          this.entCode = res.data.info.wechat_img
        }
      })
    },
    toDownLoad(){
      // this.$router.push('/download')
      if((this.hostUrl == "iot.yunruiot.com" || this.hostUrl == "iots.yunruiot.com")){
        window.location.href="https://a.app.qq.com/o/simple.jsp?pkgname=com.yunrui.gexingshangwang"
      return false
    }
    },
    closeDown(){
      this.downShow = false
    },
    // 点击切换wifi
    clkShowChange(){
      this.newswifiShow = true
    },
    // 根据返回设备状态判断来进行跳转
    goSwitch(){
      if(this.noclickBtn){
        return false
      }
      if(this.info.iccid_status == "套餐到期"){
        this.newloginpath()
      }else{
        this.goPick()
      }
    },
    // 更改过wifi名字之后
    overGetlist(){
      this.setName = false
    },
    // 切换wifi设备
   async change(){
      console.log('切换这个');
      this.phoneShow = false
      this.noclickBtn = true
      await this.getinfo()
      this.newswifiShow = !this.newswifiShow
      this.noclickBtn = false
      this.clkRes()
    },
    // 点击完成设置修改wifi别名
    setAName(){
      let data = {
        iccid:sessionStorage.getItem("iccid"),
        name:this.wifiName
      }
      this.setAliasName({data}).then(res =>{
        if(this.wifiName == ""){
          this.$toast("WIFI名称不能为空")
          return false
        }
        if(res.data.code == 1){
          this.$toast.success("设置成功")
          this.editShow = false
          sessionStorage.setItem("wifiName",this.wifiName)
          this.showWName = sessionStorage.getItem('wifiName')
          this.setName = true
          this.wifiName = ""
        }else{
          this.$toast(`${res.data.msg}`)
        } 
      })
    },
    // 点击订购套餐
    newloginpath(){
      let data = {
        path:"/selectpack",
        iccid:sessionStorage.getItem("iccid"),
        phone:sessionStorage.getItem("phone")
      }
      this.isopenid(data)
    },
    // 去实名认证
    goPick(){
         window.sessionStorage.setItem("openShow",1)
         window.location.href = this.info.real_url
    }, 
     // 进行仪表盘累加,当做加载动画
    addValue(){
    // sessionStorage.setItem("surplus","--")
    this.canverClire()
    this.setit= setInterval(() =>{
      if(this.claerset){
        clearInterval(this.setit)
        return false
      }
      if(this.value >90){
        this.value = 20
      }else{
        this.value = this.value+2
      }
      this.canverClire(this.value)
      },1000)
    },
    //刷新数据按钮
    clkRes(){
     this.surplus_flow = "--"
     this.claerset = false
     this.value = 0
     this.isrotate = false
     this.textRotate = "刷新中..."
     this.infolist[1].money =""
     this.infolist[1].company = ""
     this.infolist[1].isloading = true
     this.addValue()
     this.getoffinfo(1).then(res=>{
      this.infolist[1].money = Math.round(Math.ceil(this.info.total_flow -  this.infolist[1].usedMoney)/1024)>=0?Math.round(Math.ceil(this.info.total_flow -  this.infolist[1].usedMoney)/1024):0
      // this.infolist[1].money = Math.ceil(this.info.total_flow -  this.infolist[1].usedMoney)/1024
      this.infolist[1].company ="GB"

    })
    //  console.log( this.infolist,' this.infolist');
    },
    // echarts图表数据进行画图
    canverClire(value){ 
      this.option.series[0].data[0].value = value
      // this.option.series[0].detail.formatter = sessionStorage.getItem("surplus")
      this.myChartDom.setOption(this.option,true)
    },
    // 路由跳转
    gopath(path){
      this.$router.push(path)
    },
    // 获取首页的所有信息
    getinfo(){
      let data ={
        iccid:sessionStorage.getItem("iccid")?sessionStorage.getItem("iccid"):this.iccid
      }
      this.getindexinfo({data}).then(res =>{
        // console.log(res,'iccidinfo');
        if(res.data.code == 1){
          this.info = res.data.info
          // 修改
          console.log(this.info);
          if(this.info.sl_real_state == 0){
            // 有时接口过慢，如果切换设备的弹窗开启 主动隐藏掉
            this.phoneShow = true
            this.newswifiShow = false
          }else{
            this.phoneShow = false
          }
          window.sessionStorage.setItem("wifiName",this.info.alias_name)
          this.showWName = this.info.alias_name
          if(this.info.iccid_status != "正常"){
            this.infolist[3].texttop = "#fff600"
          }
          if(this.info.buy_id > 0){
            if(this.info.ispick != 2){
            this.isShowToast = true
            }else{
             this.isShowToast = false
            }
          }else{
            this.isShowToast = false
          }
          this.infolist[0].money = this.info.c_balance
          this.infolist[2].money = this.info.exp_days
          this.infolist[3].money = this.info.iccid_status
          sessionStorage.setItem("phone",res.data.info.phone)
          // if(this.info.real_type == 4){
          //   this.funbox.filter((item) => {
          //     if(item.path.indexOf("/changeNetwork") == -1){
          //       this.isShowNetwork = false
          //     }else{
          //       this.isShowNetwork = true
          //     }
          //   })
          // }else{
          //   this.isShowNetwork = true
          // }
          // if(!this.isShowNetwork){
          //   this.funbox.push({
          //     url:require("@/assets/images/index/changeNetwork.png"),
          //     text:"切换网络",
          //     path:"/changeNetwork" + '?dn='+ this.iccid,
          //     isswitch:false,
          //   })
          // }
        }else{
          this.$toast(`${res.data.msg}`)
        }
      })
    },
    changeNetwork(){
      // console.log(sessionStorage.getItem("iccid"),'这是');
      // this.$router.push({
      //   path:"/changeNetwork" + '?dn='+ this.iccid
      // })
      window.location.href = 'http://iot.yunruiot.com/changeNetwork?dn='+sessionStorage.getItem("iccid")
    },
    // 获取iccid
    setIccid(){
      if(sessionStorage.getItem("iccid") != undefined && sessionStorage.getItem("iccid") != null && sessionStorage.getItem("iccid") != "null" && sessionStorage.getItem("iccid") != "undefined" ){
        this.iccid = sessionStorage.getItem("iccid")
      }else{
        this.iccid = this.$route.query.dn
        sessionStorage.setItem("iccid",this.iccid)
      }
    },
    // 父组件监听到了子组件的值改变之后去关闭遮罩层
    closeshow($event){
      this.show = $event
    },
    // 点击设备重启
    restart(){
      if(JSON.stringify(this.info) == "{}"){
        return false
      }
      this.show = true
    },
     // 确定重启当前卡片
   async queryrestart(){
     this.restartloading = true
     this.show = false
     await this.getoffinfo(1).then(res=>{
      this.infolist[1].money =Math.round(Math.ceil(this.info.total_flow -  this.infolist[1].usedMoney)/1024)>=0?Math.round(Math.ceil(this.info.total_flow -  this.infolist[1].usedMoney)/1024):0
      this.infolist[1].company ="GB"
    })
     await this.getinfo()
    },
     //10秒倒计时执行完毕
     closeint(){
       this.restartloading =false
       this.complete =true
    },
    // 20秒倒计时结束
    clcomplete(){
      this.complete = false
    },
    // 点击重启按钮
    clkrestart(){
      this.show = true
    },
  },
  components:{
    funboxitem,
    umask,
    restartloading,
    complete,
    newswifi,
    bindphone,
    qrcode,
  }
}
</script>
<style lang="less" scoped>
.index{
  min-height: 100vh;
  background-image: url("~@/assets/images/index/bgimg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .indexr-center{
    width: 345px;
    margin: 0 auto;
    .emptybox{
      width: 100%;
      height: 25px;
    }
    .center-top{
      box-sizing: border-box;
      width: 100%;
      display: flex;
      padding: 0 5px;
      justify-content: space-between;
      justify-items: center;
      align-items: center;
      .left{
        font-size: 18px;
        color: #ffffff;
        font-weight: 600;
        display: flex;
        justify-items: center;
        align-items: center;
        .texttitle{
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 180px;
        }
        .wifi-img{
          width: 15px;
          display: block;
        }
        .division{
          width: 4px;
          display: block;
          margin: 0 8px 0 6px;
        }
        .left-status{
          margin-left: 12px;
          color: #c6dcff;
          transform: scale(0.9);
          font-size: 12px;
          border-radius: 10px;
          box-sizing: border-box;
          padding: 5px 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          justify-items: center;
          background-color: #2b7adc;
          position: relative;
          white-space: nowrap;
          .img{
            height: 12px;
            display: block;
            margin-left: 4px;
          }
          .hron{
            position: absolute;
            width: 14px;
            height: 10px;
            display: block;
            top:24px;
          }
        }
        .timeout{
          color: #ffca71 !important;
        }
        .noBuy{
          color: #fff600 !important;
          padding: 4px 10px;
        }
        .noRName{
          color: #ef0d0d !important;
          background-color: #fff600;
        }
      }
      .right{
        width: 14px;
        display: block;
        height: 14px;
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-image: url("~@/assets/images/index/edit.png");
      }
    }
    .echarts{
      width: 300px;
      height: 300px;
      background-image: url("~@/assets/images/index/eachartsbg.png");
      background-size: 100% auto;
      background-repeat: no-repeat;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
        .echbox-text{
          position: absolute;
          min-height: 140px;
          top: 150px;
          left: 0;
          right: 0;
          margin: 0 auto;
          display: flex;
          justify-items: center;
          align-items: center;
          flex-direction: column;
          justify-content: space-between;
          .text-top{
            color: #fff;
            font-size: 36px;
            font-family: "bahn";
            .text-sapn{
              font-size: 18px;
            }
          }
          .newsurplus{
            width: 76px;
            height: 18px;
          }
          .surplus{
            width: 76px;
            height: 18px;
            border-radius: 9px;
            color: #fff000;
            background-color: #1c6fe7;
            font-size: 12px;
            margin-top: 20px;
            text-align: center;
            line-height:18px;
            .surplus-sapn{
              font-size: 12px;
              transform: scale(0.8);
            }
          }
          .text-center{
            background-color: #02cdc1;
            display: flex;
            justify-content: center;
            align-items: center;
            justify-items: center;
            min-width: 100px;
            min-height: 28px;
            border-radius: 14px;
            color: #fff;
            font-size: 12px;
            .text-center-img{
              width: 13px;
              display: block;
              margin-right: 4px;
            }
          }
          .text-change{
            background-color: #02cdc1;
            display: flex;
            justify-content: center;
            align-items: center;
            justify-items: center;
            min-width: 84px;
            min-height: 28px;
            border-radius: 14px;
            color: #fff;
            font-size: 12px;
            @-webkit-keyframes change{
            0%{-webkit-transform:rotate(0deg);}
            50%{-webkit-transform:rotate(180deg);}
            100%{-webkit-transform:rotate(360deg);}
            }
            .text-center-img{
              width: 13px;
              display: block;
              margin-right: 4px;
              animation: change 1s linear infinite;
            }
          }
          .text-center:active{
            background-color: #02b7ac;
          }
          .text-right{
            display: flex;
            flex-direction: column;
            justify-items: center;
            align-items: center;
            .textrp{
              color: #99c0ff;
              margin-bottom: 6px;
            }
            .texttb{
              color: #fff;
              font-size: 32px;
            }
          }
        }
      
    }
    .timeoutImg{
      background-image: url("~@/assets/images/index/time.gif") !important;
    }
    .noNameTr{
      width: 290px;
      height: 96px;
      background-image: url("~@/assets/images/index/noname.gif");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 26px 0 0 80px;
      z-index: 3;
      .toptitle{
        color: #fff600;
        font-size: 16px;
        font-family: "bahn";
        font-weight: 600;
        margin-bottom: 8px;  
      }
      .bottomtitle{
        color: #fff5ae;
        font-size: 12px;
        font-family: "bahn";
      }
    }
    .downLoad{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0px 10px 0px;
      background: rgba(23,87,194,0.8);
      border-radius: 8px;
      margin-top: 12px;
      position: absolute;
      left: 50%;
      width: 94%;
      margin-left: -47%;
      z-index: 6;
      .downLoad_left{
        display: flex;
        align-items: center;
        .downLoad_title{
          font-weight: 500;
          color: #FFFFFF;
          font-size: 12px;
        }
        .downLoad_tip{
          font-size: 12px;
          color: #A6C4F4;
          font-weight: 500;
          margin-top: 10px;
        }
        img{
          width: 26px;
          height: 26px;
          border-radius: 4px;
          margin: 0 7px 0 2px;
        }
      }
      .downLoad_right{
        display: flex;
        align-items: center;
        button{
          // width: 64px;
          // height: 32px;
          background: #F6E780;
          box-shadow: 0px 5px 8px 0px rgba(40,73,159,0.2), -8px -10px 26px 0px rgba(167,95,26,0.6);
          border-radius: 12px;
          font-weight: bold;
          color: #F73412;
          font-size: 12px;
          margin-right: 10px;
          padding: 6px 10px;
          margin-left: 8px;
        }
      }
    }
    .funbox{
      width: 100%;
      height: 108px;
      border-top: #338bf5 solid 1px;
      display: flex;
      flex-wrap: wrap;
    }
    .kefu{
      position: fixed;
      top: 210px;
      right: 0;
      img{
        width: 55px;
      }
    }
    .info{
      height: 145px;
      width: 100%;
      background-color: #2173e3;
      margin-bottom: 25px;
      border-radius: 6px;
      position: relative;
      overflow: hidden;
      .info_ICCID{
        padding: 18px 13px;
        font-weight: 500;
        font-size: 13px;
        color: #BAD4FF;
      }
      .change-gif{
        position: absolute;
        top: 0;
        right: 0;
        img{
          height: 57px;
        }
      }
      .info-top{
        height: 30px;
      }
      .infobox{
        height: 48px;
        display: flex;
        flex-wrap: wrap;
        // margin-bottom: 30px;
        .text-top{
          font-size: 12px;
          color: #ffffff;
          .isstatus{
            font-size: 14px !important;
            line-height: 24px;
            text-align: center;
          }
          .sapn{ 
          font-size: 24px;
          color: #ffffff;
          font-weight: 500;
          font-family: "bahn";
          }
        }
      }
      .info-bottom{
        width: 320px;
        // width: 150px;
        height: 46px;
        margin: 0px auto;
        background-image: url("~@/assets/images/index/monPack.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        // background: linear-gradient(90deg, #357EEA 0%, #2271E6 100%);
        // padding: 7px 6px;
        // border-radius: 6px;
        .bottom-left{
          color: #ffffff;
          font-weight: 600;
          margin: 24px 0 0 28px;
          // img{
          //   width: 20px;
          //   height: 20px;
          // }
          .sapn{
            font-size: 14px;
            color: red;
          }
        }
        .bottom-right{

          width: 56px;
          height: 20px;
          border-radius: 4px;
          background-color: #4c9aff;
          color: #ffffff;
          font-weight: 600;
          margin: 20px 10px 0 0;
          display: flex;
          justify-content: center;
          align-items: center;
          justify-items: center;
        }
      }
    }
  }
  .toastBuyName{
      width: 100%;
      height: 40px;
      background-color: rgba(17, 101, 213, 0.7);
      position: absolute;
      top: 8vh;
      font-size: 12px;
      color: #fff600;
      display: flex;
      justify-content: space-between;
      align-items: center;
      justify-items: center;
      box-sizing: border-box;
      padding: 0 15px;
      .Nright{
        width: 54px;
        height: 24px;
        background-color: #fff600;
        border-radius: 12px;
        color: #0c3264;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        line-height: 24px;
      }
  }
  .issmall{
    box-sizing: border-box;
    padding-bottom: 60px;
  }
  /deep/.van-overlay{
    z-index: 6 !important;
  }
  .restartcard{
    width: 80%;
    border-radius: 5%;
    height: 275px;
    position: fixed;
    left: 50%;
    top: 50%;
    margin: auto;
    transform: translate(-50%,-50%);
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .rcimg{
      width: 80px;
      height: 80px;
      display: block;
    }
    .rectitle{
      height: 31px;
      font-weight: 900;
      font-size: 17px;
    }
    .rectoast{
      margin-bottom: 20px;
      color: rgb(68, 68, 68);
    }
    .queryclick{
      width: 85%;
      height: 48px;
      line-height: 43px;
      font-size: 17px;
      background-color: rgb(0, 146, 238);
      font-weight: 900;
      color: rgb(255, 255, 255);
      border-radius: 13px;
      border: 2px solid;
      text-align: center;
    }
    .closeclick{
      padding-top: 13px;
      color: rgb(195, 195, 195);
      font-size: 13px;
    }
  }
  .edit{
    /deep/.van-cell-group{
      width: 92%;
      margin: 16px auto;
      border-radius: 6px;
      overflow: hidden;
      display: flex;
      justify-items: center;
      align-items: center;
      height: 48px;
      .van-field{
        width: 92%;
      }
      .van-cell{
          font-size: 16px;
          font-weight: 500;
          color: #44474d;
      }
      .van-img{
        width: 14px;
        height: 14px;
        display: block;
      }
    }
     /deep/.van-cell-group::after{
       border: none;
     }
     .editButton{
       background: linear-gradient(to right,#3394f4,#2a7ff0,#236ced);
       width: 240px;
       height: 48px;
       color: #fff;
       text-align: center;
       line-height: 48px;
       border-radius: 8px 14px 4px 4px;
       margin: 0 auto;
       font-size: 16px;
       font-weight: 600;
       margin-top: 30px;
     }
     .editButton:active{
       background: #0771ff;
     }
  }
  .qrcode-img{
    width: 100%;
    display: block;
  }
  .isentCode{
  /deep/.qrcode{
    margin: 0 auto 0;
  }
  }
  /deep/.van-radio__icon{
    display: none;
  }
}
</style>